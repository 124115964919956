.logo-color {
    color: #16a34a;
}

.bg-logo-color {
    background-color: #16a34a;
}

.logo-font{
    font-family: 'Kanit', sans-serif;
}
